/**
 * Add your config changes here.
 * @module config
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    matomoSiteId: 'XKqJr8dqYR',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'Unioncamere Emilia Romagna',
      siteSubtitle: '',
      parentSiteTitle: null,
      parentSiteURL: null,
      subsiteParentSiteTitle: 'Unioncamere Emilia Romagna',
      footerNavigationDepth: 1,
      // enableCustomerSatisfaction: false, // false per disabilitare
      //arLoginUrl: '/it/area-riservata',
      //arLogoutUrl: '/logout',
      // smallFooterLinks: {
      //   default: [{ title: 'Mappa del sito', url: '/sitemap' }],
      //   it: [{ title: 'Mappa del sito', url: '/it/sitemap' }],
      //   en: [{ title: 'Sitemap', url: '/en/sitemap' }],
      // },

      headerslimTertiaryMenu: {
        it: [
          { title: 'RSS', url: '/rss-feed' },
          //{ title: 'Novità', url: '/it/novita' },
        ],
      },
      enableCustomerSatisfaction: false,
      enableVoltoFormBlockCaptcha: false,
    },

    'volto-gdpr-privacy': {
      ...config.settings['volto-gdpr-privacy'],
      defaultPanelConfig: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
        last_updated: '2023-01-02T16:30:00+00:00',
        technical: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
          choices: [
            {
              config_key: 'MATOMO',
              text: {
                it: {
                  title: 'Matomo',
                  description:
                    "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
                },
                en: {
                  title: 'Matomo',
                  description:
                    'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
                },
                fr: {
                  title: 'Matomo',
                  description:
                    "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
                },
              },
            },
            /*...config.settings[
              'volto-gdpr-privacy'
            ].defaultPanelConfig.technical.choices.filter(
              (f) =>
                f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
            ),*/
          ],
        },
      },
    },
  };

  // config.views = {
  //   ...config.views,
  // };
  // config.widgets = {
  //   ...config.widgets,
  // };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      // {
      //   id: 'cardWithSideImageTemplate',
      //   isDefault: false,
      //   title: 'Card con immagine affiancata',
      //   template: CardWithSideImageTemplate,
      //   skeleton: CardWithSideImageTemplateSkeleton,
      //   schemaEnhancer: ({ schema, formData, intl }) => {
      //     let pos = addDefaultOptions(schema, formData, intl);
      //     addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
      //     return schema;
      //   },
      // },
    ],
    listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
    listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  };

  return config;
}
